import { AxiosInstance, AxiosResponse } from 'axios';

import { IRiderLessonPackage } from '@modules/riders/types/LessonsPackage/lessonPackage';
import {
  IFindActiveRiderLessonPackageRequest,
  IUpsertRiderLessonPackageRequest,
} from '@modules/riders/types/LessonsPackage/requests';

export class RiderLessonsPackage {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Rider lessons package ----------

  findByRider(data: IFindActiveRiderLessonPackageRequest): Promise<AxiosResponse<IRiderLessonPackage>> {
    const { riderId } = data;
    return this.instance.get(`/riders/${riderId}/lessons-package`);
  }

  upsert(data: IUpsertRiderLessonPackageRequest): Promise<AxiosResponse<IRiderLessonPackage>> {
    const { riderId, ...body } = data;
    return this.instance.post(`/riders/${riderId}/lessons-package`, body);
  }
}

export const riderLessonsPackage = (instance: AxiosInstance): RiderLessonsPackage => new RiderLessonsPackage(instance);
