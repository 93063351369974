/* eslint-disable import/no-unresolved */

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useRegisterSW } from 'virtual:pwa-register/react';

import { Text } from '../Text';

export const ReloadPrompt = (): ReactElement | null => {
  const { t } = useTranslation('globals');

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW();

  const updateEquites = (): void => {
    updateServiceWorker(true);
  };

  return needRefresh ? (
    <button type="button" className="sticky top-0 z-10 w-full bg-green-600 py-3" onClick={updateEquites}>
      <div className="flex flex-col items-center gap-1">
        <Text className="text-[18px] font-bold text-white">{t('prompt_reload_title')}</Text>
        <Text className="text-white">{t('prompt_reload_click_to_update_message')}</Text>
      </div>
    </button>
  ) : null;
};
