import { ReactElement, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

import { MoonStars, Sun, X } from '@phosphor-icons/react';
import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';

import EquitesLogoWhite from '@shared/assets/images/logo-white.webp';
import EquitesLogo from '@shared/assets/images/logo.webp';
import { Switch } from '@shared/components/Switch';
import { IMenuItem, IMenuSubItem, MenuItems } from '@shared/routes/MenuItems';

import { useAuth } from '@modules/auth/hooks/useAuth';

import { useApp } from '@modules/globals/hooks/useApp';
import { useSidebar } from '@modules/globals/hooks/useSidebar';
import { useTheme } from '@modules/globals/hooks/useTheme';

import { SidebarMobileItem } from './SidebarMobileItem';
import { SidebarMobileSubItem } from './SidebarMobileSubItem';

export const SidebarMobile = (): ReactElement => {
  const { pathname } = useLocation();
  const { sidebarOpened, menuOpened, handleMenuOpened, handleMenuSelected, toggleSidebarOpened } = useSidebar();
  const { handleChangeTheme, theme } = useTheme();
  const { permissions } = useAuth();
  const { disablePtr, enablePtr } = useApp();

  const Logo = theme === 'dark' ? EquitesLogoWhite : EquitesLogo;

  const variantsItems: Variants = {
    hidden: { y: -5, opacity: 0 },
    visible: (i: number) => ({ y: 0, opacity: 1, transition: { delay: i * 0.1, type: 'tween' } }),
    exit: (i: number) => ({ y: -5, opacity: 0, transition: { delay: i * 0.1, type: 'tween' } }),
  };

  const variantsSubItems: Variants = {
    hidden: { y: -5, opacity: 0 },
    visible: (i: number) => ({ y: 0, opacity: 1, transition: { delay: i * 0.01, duration: 0.2, type: 'tween' } }),
    exit: (i: number) => ({ y: -5, opacity: 0, transition: { delay: i * 0.01, duration: 0.2, type: 'tween' } }),
  };

  const getAnimationItemsProps = (index: number, variants: Variants): MotionProps => {
    return { initial: 'hidden', animate: 'visible', exit: 'exit', custom: index, variants };
  };

  const hasPermissionToItem = (item: IMenuItem | IMenuSubItem): boolean => {
    return !item.permission || (!!item.permission && permissions.includes(item.permission));
  };

  useEffect(() => {
    const currentMainPath = MenuItems.find(item => item.path === pathname);

    if (currentMainPath) {
      handleMenuSelected(currentMainPath.id);
    }

    const currentChildPath = MenuItems.map(i => i.items)
      .flatMap(item => item)
      .find(item => item?.path === pathname);

    if (currentChildPath) {
      handleMenuSelected(currentChildPath.id);
    }
  }, [handleMenuOpened, handleMenuSelected, pathname]);

  useEffect(() => {
    // Remove scroll on background when sidebar is opened
    if (sidebarOpened) {
      if (!isDesktop) {
        disablePtr();
      }

      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      if (!isDesktop) {
        enablePtr();
      }

      document.body.style.height = 'initial';
      document.body.style.overflow = 'initial';
    }
  }, [disablePtr, enablePtr, sidebarOpened]);

  return (
    <AnimatePresence mode="wait">
      {sidebarOpened && (
        <motion.aside
          className="fixed z-50 h-full w-full flex-col bg-background-light shadow-lg dark:bg-background-dark"
          initial={{ x: -window.innerWidth, display: 'hidden' }}
          animate={{ x: 0, display: 'flex' }}
          exit={{ x: -window.innerWidth, display: 'hidden' }}
          transition={{ type: 'tween', duration: 0.5 }}
        >
          {/* Header */}
          <div className="mb-4 flex items-center justify-between px-4 pt-8">
            <Link to="/" onClick={toggleSidebarOpened} className="flex h-10 items-center justify-center">
              <img src={Logo} alt="Equites" className="h-10" />
            </Link>
            <X size={16} className="text-green-500" onClick={toggleSidebarOpened} />
          </div>

          {/* Items */}
          <div className="flex w-full flex-1 flex-col">
            {MenuItems.filter(hasPermissionToItem).map((item, index) => {
              const { id, items } = item;

              if (items) {
                return (
                  <motion.div key={id} {...getAnimationItemsProps(index, variantsItems)} className="flex flex-col">
                    <SidebarMobileItem {...item} />

                    {/* Sub items */}
                    <AnimatePresence mode="wait">
                      {menuOpened === id && (
                        <div className="ml-6 flex flex-col border-l-[1px] border-l-black-400/10 pl-2">
                          {items
                            ?.filter(hasPermissionToItem)
                            .map((subItem, subItemIndex) => (
                              <SidebarMobileSubItem
                                key={subItem.id}
                                {...getAnimationItemsProps(subItemIndex, variantsSubItems)}
                                {...subItem}
                              />
                            ))}
                        </div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                );
              }

              return <SidebarMobileItem key={id} {...getAnimationItemsProps(index, variantsItems)} {...item} />;
            })}
          </div>

          {/* Toggle theme */}
          <div className="flex items-center justify-center gap-1 pb-8">
            <Sun size={16} className="text-gray-500 dark:text-gray-400" />
            <Switch checked={theme === 'dark'} onCheckedChange={() => handleChangeTheme()} />
            <MoonStars size={16} className="text-gray-500 dark:text-gray-400" />
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
};
