import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { IRiderHealth } from '@modules/riders/types/Health/health';
import {
  ICreateEmergencyContactRequest,
  ICreateEmergencyContactResponse,
  IDeleteEmergencyContactRequest,
  IUpdateRiderMedicalInformationRequest,
  IUpdateRiderMedicalInformationResponse,
} from '@modules/riders/types/Health/requests';
import {
  ICreateRiderRequest,
  ICreateRiderResponse,
  IDeleteRiderRequest,
  IDeleteRiderResponsibleRequest,
  IFindRidersRequest,
  IFindRidersSummaryRequest,
  IUpdateRiderAddressRequest,
  IUpdateRiderAddressResponse,
  IUpdateRiderAvatarRequest,
  IUpdateRiderAvatarResponse,
  IUpdateRiderMergeProfileRequest,
  IUpdateRiderRequest,
  IUpdateRiderResponse,
  IUpdateRiderResponsibleRequest,
  IUpdateRiderResponsibleResponse,
  IUpdateRiderSharingStatusRequest,
} from '@modules/riders/types/Riders/requests';
import { IRider, IRiderAbout, IRiderProfile, IRiderSummary } from '@modules/riders/types/Riders/riders';

export class Riders {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Riders ----------

  createRider(data: ICreateRiderRequest): Promise<AxiosResponse<ICreateRiderResponse>> {
    return this.instance.post(`/riders`, data);
  }

  async deleteRider(data: IDeleteRiderRequest): Promise<void> {
    const { riderId } = data;

    await this.instance.delete(`/riders/${riderId}`);
  }

  async getRiders(params: IFindRidersRequest): Promise<IPaginateDTO<IRider>> {
    const response = await this.instance.get(`/riders`, { params });

    return response.data;
  }

  updateRider(data: IUpdateRiderRequest): Promise<AxiosResponse<IUpdateRiderResponse>> {
    const { id, ...body } = data;
    return this.instance.patch(`/riders/${id}`, body);
  }

  // ---------- Avatar ----------

  updateRiderAvatar(data: IUpdateRiderAvatarRequest): Promise<AxiosResponse<IUpdateRiderAvatarResponse>> {
    const { id, file } = data;

    const formData = new FormData();
    formData.append('avatar', file);

    return this.instance.patch(`/riders/${id}/avatar`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // ---------- About ----------

  getRiderAboutByRiderId(riderId: string): Promise<AxiosResponse<IRiderAbout>> {
    return this.instance.get(`/riders/${riderId}/about`);
  }

  // ---------- Addresses ----------

  updateRiderAddress(data: IUpdateRiderAddressRequest): Promise<AxiosResponse<IUpdateRiderAddressResponse>> {
    const { riderId, ...body } = data;
    return this.instance.patch(`/riders/${riderId}/addresses`, body);
  }

  // ---------- Emergency contacts ----------

  createEmergencyContact(
    data: ICreateEmergencyContactRequest,
  ): Promise<AxiosResponse<ICreateEmergencyContactResponse>> {
    const { riderId, ...body } = data;
    return this.instance.post(`/riders/${riderId}/emergency-contacts`, body);
  }

  deleteEmergencyContact(data: IDeleteEmergencyContactRequest): Promise<AxiosResponse<void>> {
    const { emergencyContactId, riderId } = data;
    return this.instance.delete(`/riders/${riderId}/emergency-contacts/${emergencyContactId}`);
  }

  // ---------- Health ----------

  getRiderHealthByRiderId(riderId: string): Promise<AxiosResponse<IRiderHealth>> {
    return this.instance.get(`/riders/${riderId}/health`);
  }

  // ---------- Medical information ----------

  updateMedicalInformation(
    data: IUpdateRiderMedicalInformationRequest,
  ): Promise<AxiosResponse<IUpdateRiderMedicalInformationResponse>> {
    const { riderId, ...body } = data;
    return this.instance.patch(`/riders/${riderId}/medical-information`, body);
  }

  // ---------- Profiles ----------

  getRiderProfileByRiderId(riderId: string): Promise<AxiosResponse<IRiderProfile>> {
    return this.instance.get(`/riders/${riderId}/profile`);
  }

  // ---------- Responsible ----------

  deleteResponsible(data: IDeleteRiderResponsibleRequest): Promise<AxiosResponse<void>> {
    const { riderId } = data;
    return this.instance.delete(`/riders/${riderId}/responsible`);
  }

  updateResponsible(data: IUpdateRiderResponsibleRequest): Promise<AxiosResponse<IUpdateRiderResponsibleResponse>> {
    const { riderId, ...body } = data;
    return this.instance.patch(`/riders/${riderId}/responsible`, body);
  }

  // ---------- Summary ----------

  async getRidersSummary(params?: IFindRidersSummaryRequest): Promise<IRiderSummary[]> {
    const response = await this.instance.get(`/riders/summary`, { params });

    return response.data;
  }

  // ---------- Sharing status ----------

  async updateSharingStatus(data: IUpdateRiderSharingStatusRequest): Promise<void> {
    const { riderId, ...body } = data;

    await this.instance.put(`/riders/${riderId}/sharing`, body);
  }

  updateMergeProfile(data: IUpdateRiderMergeProfileRequest): Promise<AxiosResponse<void>> {
    const { originRiderId, targetRiderId, ...body } = data;

    return this.instance.put(`/riders/${originRiderId}/merge/riders/${targetRiderId}`, body);
  }
}

export const riders = (instance: AxiosInstance): Riders => new Riders(instance);
