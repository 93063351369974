import { AppointmentRoles } from './appointments';
import { AppointmentModalityRoles } from './appointmentsModalities';
import { BalanceRoles } from './balances';
import { ConfigurationRoles } from './configurations';
import { ContactsRoles } from './contacts';
import { DashboardRoles } from './dashboard';
import { HorseRoles } from './horses';
import { InvoiceRoles } from './invoices';
import { RiderRoles } from './riders';
import { TeacherRoles } from './teachers';
import { UserRoles } from './users';
import { WarehouseRoles } from './warehouse';
import { WithdrawalsRoles } from './withdrawals';

/**
 * Roles to allow users access functions on Equites
 * @readonly
 * @property {AppointmentRoles} appointments
 * @property {AppointmentModalityRoles} appointmentsModalities
 * @property {BalanceRoles} balances
 * @property {ConfigurationRoles} configurations
 * @property {ContactsRoles} contacts
 * @property {DashboardRoles} dashboard
 * @property {HorseRoles} horses
 * @property {InvoiceRoles} invoices
 * @property {RidersRoles} riders
 * @property {TeacherRoles} teachers
 * @property {UserRoles} users
 * @property {WarehouseRoles} warehouse
 * @property {WithdrawalsRoles} withdrawals
 */
export const Roles = {
  /** Roles to manage appointments */
  appointments: AppointmentRoles,

  /** Roles to manage appointments modalities */
  appointmentsModalities: AppointmentModalityRoles,

  /** Roles to manage balances */
  balances: BalanceRoles,

  /** Roles to manage configurations */
  configurations: ConfigurationRoles,

  /** Roles to manage contacts */
  contacts: ContactsRoles,

  /** Roles to manage dashboard */
  dashboard: DashboardRoles,

  /** Roles to manage horses */
  horses: HorseRoles,

  /** Roles to manage invoices */
  invoices: InvoiceRoles,

  /** Roles to manage riders */
  riders: RiderRoles,

  /** Roles to manage teachers */
  teachers: TeacherRoles,

  /** Roles to manage users */
  users: UserRoles,

  /** Roles to manage warehouse */
  warehouse: WarehouseRoles,

  /** Roles to manage withdrawals */
  withdrawals: WithdrawalsRoles,
};
