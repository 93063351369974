import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { HandleDate } from '@shared/utils/HandleDate';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IRiderPaymentContext } from '@modules/riders/types/Payments/context';
import { IRiderPayment, IRiderTotalPayments } from '@modules/riders/types/Payments/payments';
import {
  ICreateRiderPaymentRequest,
  IDeleteRiderPaymentRequest,
  IFindRidersPaymentsRequest,
  IFindRidersTotalPaymentsRequest,
  IUpdateRiderPaymentRequest,
} from '@modules/riders/types/Payments/requests';

const RiderPaymentContext = createContext({} as IRiderPaymentContext);
RiderPaymentContext.displayName = 'RiderPayments';

const RiderPaymentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('riders', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [riderPayments, setRiderPayments] = useState({} as IPaginateDTO<IRiderPayment>);
  const [filterRiderPayments, setFilterRiderPayments] = useState({} as IFindRidersPaymentsRequest);
  const [paymentsSelected, setPaymentsSelected] = useState<IRiderPayment[]>([]);
  const [riderTotalPayments, setRiderTotalPayments] = useState({} as IRiderTotalPayments);

  const findRidersPayments = useCallback(
    async (data: IFindRidersPaymentsRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riderPayments().find(data);

        const items = response.data.items.map(payment => ({
          ...payment,
          dueFormatted: payment.due
            ? new HandleDate().setDateString(payment.due, 'yyyy-MM-dd').format('dd/MM/yyyy')
            : '',
          paidFormatted: payment.paid
            ? new HandleDate().setDateString(payment.paid, 'yyyy-MM-dd').format('dd/MM/yyyy')
            : '',
        }));

        setRiderPayments({ ...response.data, items });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const findRidersTotalPayments = useCallback(
    async (data: IFindRidersPaymentsRequest) => {
      try {
        startLoad();

        const payload: IFindRidersTotalPaymentsRequest = {
          from: data.from,
          riderId: data.riderId,
          search: data.search,
          status: data.status,
          to: data.to,
        };

        const response = await CompaniesClient.riderPayments().findTotal(payload);

        setRiderTotalPayments(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createRiderPayment = useCallback(
    async (data: ICreateRiderPaymentRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderPayments().createPayment(data);

        await findRidersPayments(filterRiderPayments);

        toast(t('rider_payment_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterRiderPayments, findRidersPayments, startLoad, t],
  );

  const deleteRiderPayment = useCallback(
    async (data: IDeleteRiderPaymentRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderPayments().deletePayment(data);

        await findRidersPayments(filterRiderPayments);

        toast(t('rider_payment_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterRiderPayments, findRidersPayments, startLoad, t],
  );

  const updateRiderPayment = useCallback(
    async (data: IUpdateRiderPaymentRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderPayments().updatePayment(data);

        await findRidersPayments(filterRiderPayments);

        toast(t('rider_payment_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, filterRiderPayments, findRidersPayments, startLoad, t],
  );

  const exportRidersPaymentsCsv = useCallback(
    async (data: IFindRidersPaymentsRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderPayments().exportPaymentsCsv(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const exportRidersPaymentsPdf = useCallback(
    async (data: IFindRidersPaymentsRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderPayments().exportPaymentsPdf(data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleFilterRiderPayments = useCallback((data: Partial<IFindRidersPaymentsRequest>, reset?: boolean) => {
    setFilterRiderPayments(current => (reset ? (data as IFindRidersPaymentsRequest) : { ...current, ...data }));
  }, []);

  const handlePaymentsSelected = useCallback((data: IRiderPayment[]) => {
    setPaymentsSelected(data);
  }, []);

  const contextValue = useMemo<IRiderPaymentContext>(
    () => ({
      createRiderPayment,
      deleteRiderPayment,
      exportRidersPaymentsCsv,
      exportRidersPaymentsPdf,
      filterRiderPayments,
      findRidersPayments,
      findRidersTotalPayments,
      handleFilterRiderPayments,
      handlePaymentsSelected,
      paymentsSelected,
      riderPayments,
      riderTotalPayments,
      updateRiderPayment,
    }),
    [
      createRiderPayment,
      deleteRiderPayment,
      exportRidersPaymentsCsv,
      exportRidersPaymentsPdf,
      filterRiderPayments,
      findRidersPayments,
      findRidersTotalPayments,
      handleFilterRiderPayments,
      handlePaymentsSelected,
      paymentsSelected,
      riderPayments,
      riderTotalPayments,
      updateRiderPayment,
    ],
  );

  return <RiderPaymentContext.Provider value={contextValue}>{children}</RiderPaymentContext.Provider>;
};

export { RiderPaymentContext, RiderPaymentProvider };
