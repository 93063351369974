import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Hammer } from '@phosphor-icons/react';

import { FeatureFlagKeys } from '@shared/components/FeatureFlag/constants/FeatureFlagKeys';
import { Text } from '@shared/components/Text';

export const Maintenance = (): ReactElement => {
  const { t } = useTranslation('globals');

  const navigate = useNavigate();

  const inMaintenanceIsEnabled = useFeatureIsOn(FeatureFlagKeys.IN_MAINTENANCE);

  // Check feature flag in-maintenance mode is enabled
  useEffect(() => {
    if (!inMaintenanceIsEnabled) {
      navigate('/', { replace: true });
    }
  }, [inMaintenanceIsEnabled, navigate]);

  return (
    <main className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex h-24 w-24 items-center justify-center rounded-full shadow-lg dark:border dark:border-black-400">
        <Hammer size={68} className="text-green-500" />
      </div>

      <Text className="mb-2 mt-4 text-center text-[18px] font-semibold text-black-400 dark:text-white">
        {t('in_maintenance_title')}
      </Text>

      <Text className="text-center text-md font-light text-gray-400">{t('in_maintenance_description')}</Text>
    </main>
  );
};
