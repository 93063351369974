/**
 * BalanceRoles
 * @readonly
 * @enum {string}
 */
export enum BalanceRoles {
  /** Create a new balance. */
  CREATE_BALANCE = 'CREATE_BALANCE',

  /** Create a new balance category. */
  CREATE_BALANCE_CATEGORY = 'CREATE_BALANCE_CATEGORY',

  /** Create a new balance wallet. */
  CREATE_BALANCE_WALLET = 'CREATE_BALANCE_WALLET', // new

  /** Delete a balance. */
  DELETE_BALANCE = 'DELETE_BALANCE',

  /** Delete a balance attachment. */
  DELETE_BALANCE_ATTACHMENT = 'DELETE_BALANCE_ATTACHMENT',

  /** Delete a balance category. */
  DELETE_BALANCE_CATEGORY = 'DELETE_BALANCE_CATEGORY',

  /** Delete a balance wallet. */
  DELETE_BALANCE_WALLET = 'DELETE_BALANCE_WALLET',

  /** Export balances movements as PDF or CSV. */
  EXPORT_BALANCES_MOVEMENTS = 'EXPORT_BALANCES_MOVEMENTS', // new

  /** Export balances reports as PDF or CSV. */
  EXPORT_BALANCES_REPORTS = 'EXPORT_BALANCES_REPORTS', // new

  /** View balances. */
  GET_BALANCE = 'GET_BALANCE',

  /** View balances categories. */
  GET_BALANCE_CATEGORIES = 'GET_BALANCE_CATEGORY',

  /** View a balance reports. */
  GET_BALANCE_REPORTS = 'GET_BALANCE_REPORTS', // new

  /** View balances categories. */
  GET_BALANCE_WALLETS = 'GET_BALANCE_WALLETS', // new

  /** Show cards with balance general and each wallet on balance dashboard. */
  GET_BALANCES_DASHBOARD_AMOUNTS = 'GET_BALANCES_DASHBOARD_AMOUNTS', // new

  /** Show card with balances of current month on balance dashboard. */
  GET_BALANCES_DASHBOARD_BALANCES_OF_MONTH = 'GET_BALANCES_DASHBOARD_BALANCES_OF_MONTH', // new

  /** Show cards with chart of provisioned and consolidated views on balance dashboard. */
  GET_BALANCES_DASHBOARD_CONSOLIDATED_AND_PROVISIONED = 'GET_BALANCES_DASHBOARD_CONSOLIDATED_AND_PROVISIONED', // new

  /** Show card with next movements on balance dashboard. */
  GET_BALANCES_DASHBOARD_NEXT_MOVEMENTS = 'GET_BALANCES_DASHBOARD_NEXT_MOVEMENTS', // new

  /** Update a balance. */
  UPDATE_BALANCE = 'UPDATE_BALANCE',

  /** Update a balance category. */
  UPDATE_BALANCE_CATEGORY = 'UPDATE_BALANCE_CATEGORY',

  /** Update a balance wallet. */
  UPDATE_BALANCE_WALLET = 'UPDATE_BALANCE_WALLET', // new

  /** Upload a new attachment to balance. */
  UPLOAD_BALANCE_ATTACHMENT = 'UPLOAD_BALANCE_ATTACHMENT',
}
