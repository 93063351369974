import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateOwnerRequest,
  ICreateOwnerResponse,
  IDeleteOwnerRequest,
  IFindOwnersByCompanyIdRequest,
  IFindOwnersByCompanyIdResponse,
  IUpdateOwnerRequest,
  IUpdateOwnerResponse,
} from '@modules/owners/types/Owners/requests';

type IGetOwnersResponse = Promise<AxiosResponse<IFindOwnersByCompanyIdResponse>>;

export class Owners {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Owners ----------

  createOwner(data: ICreateOwnerRequest): Promise<AxiosResponse<ICreateOwnerResponse>> {
    return this.instance.post(`/owners`, data);
  }

  deleteOwner(data: IDeleteOwnerRequest): Promise<AxiosResponse<void>> {
    const { ownerId } = data;
    return this.instance.delete(`/owners/${ownerId}`);
  }

  getOwners(params: IFindOwnersByCompanyIdRequest): IGetOwnersResponse {
    return this.instance.get(`/owners`, { params });
  }

  updateOwner(data: IUpdateOwnerRequest): Promise<AxiosResponse<IUpdateOwnerResponse>> {
    const { ownerId, ...body } = data;
    return this.instance.patch(`/owners/${ownerId}`, body);
  }
}

export const owners = (instance: AxiosInstance): Owners => new Owners(instance);
