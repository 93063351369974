import { ReactElement, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';

import queryString from 'query-string';

import { Fallback } from '@shared/components/Fallback';

import { useProfile } from '@modules/profiles/hooks/useProfile';

import { useSubscriptions } from '@modules/subscriptions/hooks/useSubscriptions';

import { useAuth } from '../hooks/useAuth';

export const Auth = (): ReactElement => {
  const location = useLocation();

  const { signInByTokens, isAuthenticated } = useAuth();
  const { getCurrentProfile } = useProfile();
  const { getCurrentSubscription } = useSubscriptions();

  const { accessToken, equitesToken, refreshToken } = queryString.parse(location.search);

  useEffect(() => {
    if (accessToken && equitesToken && refreshToken) {
      signInByTokens({
        accessToken: String(accessToken),
        equitesToken: String(equitesToken),
        refreshToken: String(refreshToken),
      });
    }
  }, [accessToken, equitesToken, refreshToken, signInByTokens]);

  useAsync(async () => {
    if (isAuthenticated) {
      await Promise.all([getCurrentProfile(), getCurrentSubscription()]);
    }
  }, [isAuthenticated]);

  return <Suspense fallback={<Fallback.Navigation />} />;
};
