import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { Storage } from '@shared/constants/Storage';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IRiderContext } from '@modules/riders/types/Riders/context';
import {
  ICreateRiderRequest,
  IDeleteRiderRequest,
  IDeleteRiderResponsibleRequest,
  IUpdateRiderAddressRequest,
  IUpdateRiderAvatarRequest,
  IUpdateRiderMergeProfileRequest,
  IUpdateRiderRequest,
  IUpdateRiderResponsibleRequest,
  IUpdateRiderSharingStatusRequest,
} from '@modules/riders/types/Riders/requests';
import { IRiderAbout, IRiderProfile, IRiderSummary } from '@modules/riders/types/Riders/riders';

const RidersContext = createContext({} as IRiderContext);
RidersContext.displayName = 'Riders';

const RidersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('riders', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [riderAbout, setRiderAbout] = useState({} as IRiderAbout);
  const [riderCreationPayload, setRiderCreationPayload] = usePersistedState(
    Storage.RIDER_TO_CREATE,
    {} as ICreateRiderRequest,
    'SESSION',
  );
  const [riderProfile, setRiderProfile] = useState({} as IRiderProfile);
  const [ridersSummary, setRidersSummary] = useState<IRiderSummary[]>([]);

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['riders:summary'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['riders:list'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['appointments:calendar'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['appointments:id'], exact: false });
    queryClient.invalidateQueries({ queryKey: ['dashboard:home'] });
  }, [queryClient]);

  const getRidersSummary = useCallback(async () => {
    try {
      startLoad();

      const response = await CompaniesClient.riders().getRidersSummary();

      setRidersSummary(response);

      sessionStorage.removeItem(Storage.RIDER_TO_CREATE);
    } catch (err) {
      HandleApiErrors.handle({ err });
    } finally {
      endLoad();
    }
  }, [endLoad, startLoad]);

  const createRider = useCallback(
    async (data?: Partial<ICreateRiderRequest>) => {
      try {
        startLoad();

        const { shouldNavigateToProfile, ...payload } = { ...riderCreationPayload, ...data };

        const response = await CompaniesClient.riders().createRider(payload);

        if (shouldNavigateToProfile) {
          navigate(`/riders/${response.data.id}/profile`);
        }

        invalidateQueries();

        toast(t('rider_created_success'), { type: 'success' });

        setRiderCreationPayload({} as ICreateRiderRequest);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, navigate, riderCreationPayload, setRiderCreationPayload, startLoad, t],
  );

  const deleteRider = useCallback(
    async (data: IDeleteRiderRequest) => {
      try {
        startLoad();

        await CompaniesClient.riders().deleteRider(data);

        invalidateQueries();

        toast(t('rider_deleted_success'), { type: 'success' });

        await getRidersSummary();
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getRidersSummary, invalidateQueries, startLoad, t],
  );

  const getRiderAboutByRiderId = useCallback(
    async (riderId: string) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().getRiderAboutByRiderId(riderId);

        setRiderAbout(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const getRiderProfileByRiderId = useCallback(
    async (riderId: string) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().getRiderProfileByRiderId(riderId);

        setRiderProfile(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const handleRiderAboutState = useCallback((data: Partial<IRiderAbout>, reset?: boolean) => {
    setRiderAbout(current => (reset ? ({ ...data } as IRiderAbout) : { ...current, ...data }));
  }, []);

  const handleRiderCreationPayload = useCallback(
    (data: ICreateRiderRequest) => {
      setRiderCreationPayload(current => ({ ...current, ...data }));
    },
    [setRiderCreationPayload],
  );

  const updateAddress = useCallback(
    async (data: IUpdateRiderAddressRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().updateRiderAddress(data);

        setRiderAbout(current => ({ ...current, address: response.data }));

        toast(t('rider_address_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const updateRider = useCallback(
    async (data: IUpdateRiderRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().updateRider(data);

        await getRiderAboutByRiderId(response.data.id);

        invalidateQueries();

        toast(t('rider_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getRiderAboutByRiderId, invalidateQueries, startLoad, t],
  );

  const updateRiderAvatar = useCallback(
    async (data: IUpdateRiderAvatarRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().updateRiderAvatar(data);

        setRiderAbout(current => ({ ...current, avatarUrl: response.data.avatarUrl }));

        invalidateQueries();

        toast(t('rider_avatar_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const deleteRiderResponsible = useCallback(
    async (data: IDeleteRiderResponsibleRequest) => {
      try {
        startLoad();

        await CompaniesClient.riders().deleteResponsible(data);

        setRiderAbout(current => ({ ...current, responsible: undefined }));
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateRiderResponsible = useCallback(
    async (data: IUpdateRiderResponsibleRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riders().updateResponsible(data);

        setRiderAbout(current => ({ ...current, responsible: response.data }));
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateRiderSharingStatus = useCallback(
    async (data: IUpdateRiderSharingStatusRequest) => {
      try {
        startLoad();

        await CompaniesClient.riders().updateSharingStatus(data);

        if (data.status === 'APPROVED') {
          setRiderProfile(current => ({ ...current, isShared: true, status: 'APPROVED' }));
        } else if (data.status === 'REJECTED') {
          setRiderProfile(current => ({ ...current, isShared: true, status: 'REJECTED' }));
        } else if (data.status === 'UNLINKED') {
          setRidersSummary(current => {
            const riders = [...current];

            const riderIndex = current.findIndex(rider => rider.id === data.riderId);

            riders[riderIndex] = { ...riders[riderIndex], isShared: false, avatarUrl: '' };

            return riders;
          });
        }

        toast(t('rider_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad, t],
  );

  const updateRiderMergeProfile = useCallback(
    async (data: IUpdateRiderMergeProfileRequest) => {
      try {
        startLoad();

        await CompaniesClient.riders().updateMergeProfile(data);

        invalidateQueries();

        toast(t('rider_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, invalidateQueries, startLoad, t],
  );

  const contextValue = useMemo<IRiderContext>(
    () => ({
      createRider,
      deleteRider,
      deleteRiderResponsible,
      getRiderAboutByRiderId,
      getRiderProfileByRiderId,
      getRidersSummary,
      handleRiderAboutState,
      handleRiderCreationPayload,
      updateAddress,
      updateRider,
      updateRiderAvatar,
      updateRiderMergeProfile,
      updateRiderResponsible,
      updateRiderSharingStatus,

      riderAbout,
      riderCreationPayload,
      riderProfile,
      ridersSummary,
    }),
    [
      createRider,
      deleteRider,
      deleteRiderResponsible,
      getRiderAboutByRiderId,
      getRiderProfileByRiderId,
      getRidersSummary,
      handleRiderAboutState,
      handleRiderCreationPayload,
      riderAbout,
      riderCreationPayload,
      riderProfile,
      ridersSummary,
      updateAddress,
      updateRider,
      updateRiderAvatar,
      updateRiderMergeProfile,
      updateRiderResponsible,
      updateRiderSharingStatus,
    ],
  );

  return <RidersContext.Provider value={contextValue}>{children}</RidersContext.Provider>;
};

export { RidersContext, RidersProvider };
