import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerGap } from '@phosphor-icons/react';
import { Slot } from '@radix-ui/react-slot';

import { Text } from '../Text';
import { buttonVariants, spinnerVariants } from './styles';
import { IButtonProps } from './types';

export const Button: FC<IButtonProps> = (props): ReactElement => {
  const {
    children,
    asChild,
    className,
    variant,
    color,
    type = 'button',
    isLoading = false,
    disabled = false,
    textOnLoading,
    ...rest
  } = props;

  const { t } = useTranslation('globals');

  const Component = asChild ? Slot : 'button';

  return (
    <Component
      className={buttonVariants({ color, variant, className })}
      disabled={disabled || isLoading}
      type={type}
      {...rest}
    >
      {isLoading ? (
        <div className="flex justify-center gap-2">
          <SpinnerGap className={spinnerVariants({ color, variant })} weight="bold" />
          <Text className="text-md">{textOnLoading ?? t('loading')}</Text>
        </div>
      ) : (
        children
      )}
    </Component>
  );
};
