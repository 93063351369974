import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IHorseOwnerContext } from '@modules/horses/types/Owners/context';
import { IHorseOwner } from '@modules/horses/types/Owners/owners';
import { IFindOwnersByHorseIdRequest, IUpdateHorseOwnersRequest } from '@modules/horses/types/Owners/requests';

const HorseOwnersContext = createContext({} as IHorseOwnerContext);
HorseOwnersContext.displayName = 'HorseOwners';

const HorseOwnersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('horses', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [horseOwners, setHorseOwners] = useState<IHorseOwner[]>([]);

  const getHorseOwners = useCallback(
    async (data: IFindOwnersByHorseIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.horseOwners().getHorseOwners(data);

        setHorseOwners(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const updateHorseOwners = useCallback(
    async (data: IUpdateHorseOwnersRequest) => {
      try {
        startLoad();

        await CompaniesClient.horseOwners().updateHorseOwners(data);

        await getHorseOwners({ horseId: data.horseId });

        toast(t('horse_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, getHorseOwners, startLoad, t],
  );

  const contextValue = useMemo<IHorseOwnerContext>(
    () => ({ getHorseOwners, horseOwners, updateHorseOwners }),
    [getHorseOwners, horseOwners, updateHorseOwners],
  );

  return <HorseOwnersContext.Provider value={contextValue}>{children}</HorseOwnersContext.Provider>;
};

export { HorseOwnersProvider, HorseOwnersContext };
