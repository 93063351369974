import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Buffer } from 'buffer';
import { createContext } from 'use-context-selector';

import { Storage } from '@shared/constants/Storage';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';
import { sleep } from '@shared/utils/SleepFunction';
import { usePersistedState } from '@shared/utils/usePersistedState';

import { ITokens } from '@modules/auth/types/Auth/auth';
import { IAuthContext } from '@modules/auth/types/Auth/context';
import { ISignInByTokensRequest } from '@modules/auth/types/Auth/requests';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { useProfile } from '@modules/profiles/hooks/useProfile';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);
AuthContext.displayName = 'Auth';

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { startLoad, endLoad } = useLoader();
  const { handleCurrentProfileState } = useProfile();

  const getTokensStored = (): ITokens | null => {
    const tokensStored = localStorage.getItem(Storage.TOKENS);
    return tokensStored ? JSON.parse(tokensStored) : null;
  };

  const getPermissionsStored = (): string[] => {
    const permissionsStored = localStorage.getItem(Storage.PERMISSIONS);
    return permissionsStored ? JSON.parse(permissionsStored) : [];
  };

  const [isAuthenticated, setIsAuthenticated] = useState(() => !!getTokensStored());
  const [tokens, setTokens] = usePersistedState<ITokens | null>(Storage.TOKENS, getTokensStored());
  const [permissions, setPermissions] = usePersistedState<string[]>(Storage.PERMISSIONS, getPermissionsStored());

  const signInByTokens = useCallback(
    async (data: ISignInByTokensRequest) => {
      try {
        startLoad();

        const { accessToken, equitesToken, refreshToken } = data;

        setIsAuthenticated(true);
        setTokens({ accessToken, equitesToken, refreshToken });

        const { pms } = JSON.parse(Buffer.from(equitesToken.split('.')[1], 'base64').toString());
        setPermissions(pms.map((permission: string) => `${permission}`));

        await sleep(150);

        navigate('/', { replace: true });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, navigate, setPermissions, setTokens, startLoad],
  );

  const signOut = useCallback(async () => {
    setIsAuthenticated(false);
    setTokens(null);
    setPermissions([]);
    handleCurrentProfileState({}, true);

    window.location.href = import.meta.env.VITE_DOMINATOR_APP_URL;
  }, [handleCurrentProfileState, setPermissions, setTokens]);

  const contextValue = useMemo<IAuthContext>(
    () => ({ isAuthenticated, tokens, permissions, signInByTokens, signOut }),
    [isAuthenticated, permissions, signInByTokens, signOut, tokens],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
