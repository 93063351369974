import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IRiderLessonPackageContext } from '@modules/riders/types/LessonsPackage/context';
import { IRiderLessonPackage } from '@modules/riders/types/LessonsPackage/lessonPackage';
import {
  IFindActiveRiderLessonPackageRequest,
  IUpsertRiderLessonPackageRequest,
} from '@modules/riders/types/LessonsPackage/requests';

const RiderLessonPackageContext = createContext({} as IRiderLessonPackageContext);
RiderLessonPackageContext.displayName = 'RiderLessonPackage';

const RiderLessonPackageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('riders', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [riderLessonPackage, setRiderLessonPackage] = useState({} as IRiderLessonPackage);

  const findLessonPackageByRider = useCallback(
    async (data: IFindActiveRiderLessonPackageRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.riderLessonsPackage().findByRider(data);

        setRiderLessonPackage(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const upsertRiderLessonPackage = useCallback(
    async (data: IUpsertRiderLessonPackageRequest) => {
      try {
        startLoad();

        await CompaniesClient.riderLessonsPackage().upsert(data);

        await findLessonPackageByRider({ riderId: data.riderId });

        toast(t('rider_lesson_package_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, findLessonPackageByRider, startLoad, t],
  );

  const contextValue = useMemo<IRiderLessonPackageContext>(
    () => ({ findLessonPackageByRider, riderLessonPackage, upsertRiderLessonPackage }),
    [findLessonPackageByRider, riderLessonPackage, upsertRiderLessonPackage],
  );

  return <RiderLessonPackageContext.Provider value={contextValue}>{children}</RiderLessonPackageContext.Provider>;
};

export { RiderLessonPackageProvider, RiderLessonPackageContext };
