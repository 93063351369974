import './shared/styles/global.css';

import { ReactElement } from 'react';
import { I18nextProvider } from 'react-i18next';

import { ReloadPrompt } from '@shared/components/ReloadPrompt';
import { i18n } from '@shared/configs/Translate';
import { Routes } from '@shared/routes';

export const App = (): ReactElement => {
  return (
    <I18nextProvider i18n={i18n}>
      <ReloadPrompt />
      <Routes />
    </I18nextProvider>
  );
};
