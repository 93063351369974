import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createContext } from 'use-context-selector';

import { CompaniesClient } from '@shared/clients/http/CompaniesClient';
import { toast } from '@shared/components/Toast';
import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';
import { HandleApiErrors } from '@shared/utils/HandleApiErrors';

import { useLoader } from '@modules/globals/hooks/useLoader';

import { IOwnerContext } from '@modules/owners/types/Owners/context';
import { IOwner, IOwnerFilter } from '@modules/owners/types/Owners/owners';
import {
  ICreateOwnerRequest,
  IUpdateOwnerRequest,
  IDeleteOwnerRequest,
  IFindOwnersByCompanyIdRequest,
} from '@modules/owners/types/Owners/requests';

const OwnersContext = createContext({} as IOwnerContext);
OwnersContext.displayName = 'Owners';

const OwnersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('owners', { keyPrefix: 'messages' });

  const { startLoad, endLoad } = useLoader();

  const [owners, setOwners] = useState({} as IPaginateDTO<IOwner>);
  const [ownersFilter, setOwnersFilter] = useState({ limit: 10, page: 1 } as IOwnerFilter);

  const getOwners = useCallback(
    async (data: IFindOwnersByCompanyIdRequest) => {
      try {
        startLoad();

        const response = await CompaniesClient.owners().getOwners(data);

        setOwners(response.data);
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, startLoad],
  );

  const createOwner = useCallback(
    async (data: ICreateOwnerRequest) => {
      try {
        startLoad();

        await CompaniesClient.owners().createOwner(data);

        await getOwners(ownersFilter);

        toast(t('owner_created_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, ownersFilter, getOwners, startLoad, t],
  );

  const updateOwner = useCallback(
    async (data: IUpdateOwnerRequest) => {
      try {
        startLoad();

        await CompaniesClient.owners().updateOwner(data);

        await getOwners(ownersFilter);

        toast(t('owner_updated_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, ownersFilter, getOwners, startLoad, t],
  );

  const deleteOwner = useCallback(
    async (data: IDeleteOwnerRequest) => {
      try {
        startLoad();

        await CompaniesClient.owners().deleteOwner(data);

        await getOwners(ownersFilter);

        toast(t('owner_deleted_success'), { type: 'success' });
      } catch (err) {
        HandleApiErrors.handle({ err });
      } finally {
        endLoad();
      }
    },
    [endLoad, ownersFilter, getOwners, startLoad, t],
  );

  const handleOwnersFilter = useCallback((data: Partial<IOwnerFilter>, reset?: boolean) => {
    setOwnersFilter(current => (reset ? ({ ...data } as IOwnerFilter) : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IOwnerContext>(
    () => ({ createOwner, deleteOwner, getOwners, handleOwnersFilter, owners, ownersFilter, updateOwner }),
    [createOwner, deleteOwner, getOwners, handleOwnersFilter, owners, ownersFilter, updateOwner],
  );

  return <OwnersContext.Provider value={contextValue}>{children}</OwnersContext.Provider>;
};

export { OwnersProvider, OwnersContext };
