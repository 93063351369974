import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { addresses, Addresses } from '@modules/addresses/services/Addresses';

import { appointments, Appointments } from '@modules/appointments/services/Appointments';
import { appointmentsLockDays, AppointmentsLockDays } from '@modules/appointments/services/AppointmentsLockDays';
import { appointmentsModalities, AppointmentsModalities } from '@modules/appointments/services/AppointmentsModalities';

import { balances, Balances } from '@modules/balances/services/Balances';
import { BalancesCategories, balancesCategories } from '@modules/balances/services/Categories';
import { balancesDashboard, BalancesDashboard } from '@modules/balances/services/Dashboard';
import { balancesReports, BalancesReports } from '@modules/balances/services/Reports';
import { balancesWallets, BalancesWallets } from '@modules/balances/services/Wallets';

import { configuration, Configuration } from '@modules/configurations/services/Configurations';
import {
  configurationNotification,
  ConfigurationNotification,
} from '@modules/configurations/services/ConfigurationsNotifications';
import { myTeam, MyTeam } from '@modules/configurations/services/MyTeam';

import { dashboard, Dashboard } from '@modules/dashboard/services/Dashboard';

import { HorseExams, horseExams } from '@modules/horses/services/Exams';
import { horseExpenses, HorseExpenses } from '@modules/horses/services/Expenses';
import { HorseFeeds, horseFeeds } from '@modules/horses/services/Feeds';
import { HorsesFeedsFoodForecast, horsesFeedsFoodForecast } from '@modules/horses/services/FeedsFoodForecast';
import { horseFoods, HorseFoods } from '@modules/horses/services/Foods';
import { horses, Horses } from '@modules/horses/services/Horses';
import { horseHorseshoes, HorseHorseshoes } from '@modules/horses/services/Horseshoes';
import { HorseMedicines, horseMedicines } from '@modules/horses/services/Medicines';
import { horseOdontology, HorseOdontology } from '@modules/horses/services/Odontology';
import { horseOwners, HorseOwners } from '@modules/horses/services/Owners';
import { HorseVaccines, horseVaccines } from '@modules/horses/services/Vaccines';

import { invoices, Invoices } from '@modules/invoices/services/Invoices';

import { owners, Owners } from '@modules/owners/services/Owners';

import { ProfileAbout, profileAbout } from '@modules/profiles/services/About';
import { profileCompanyData, ProfileCompanyData } from '@modules/profiles/services/CompanyData';
import { profile, Profile } from '@modules/profiles/services/Profile';

import { riderAppointmentRules, RiderAppointmentRules } from '@modules/riders/services/AppointmentRules';
import { riderInvites, RiderInvites } from '@modules/riders/services/Invites';
import { riderLessonsPackage, RiderLessonsPackage } from '@modules/riders/services/LessonsPackage';
import { ridersNotifications, RidersNotifications } from '@modules/riders/services/Notifications';
import { riderPayments, RiderPayments } from '@modules/riders/services/Payments';
import { Riders, riders } from '@modules/riders/services/Riders';

import { Teachers, teachers } from '@modules/teachers/services/Teachers';

import { Foods, foods } from '@modules/warehouse/services/Foods';

import { companiesApiConfig } from './config';

export class CompaniesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = companiesApiConfig(params).instance();
  }

  addresses(): Addresses {
    return addresses(this.instance);
  }

  appointments(): Appointments {
    return appointments(this.instance);
  }

  appointmentsLockDays(): AppointmentsLockDays {
    return appointmentsLockDays(this.instance);
  }

  appointmentsModalities(): AppointmentsModalities {
    return appointmentsModalities(this.instance);
  }

  balances(): Balances {
    return balances(this.instance);
  }

  balancesCategories(): BalancesCategories {
    return balancesCategories(this.instance);
  }

  balancesDashboard(): BalancesDashboard {
    return balancesDashboard(this.instance);
  }

  balancesReports(): BalancesReports {
    return balancesReports(this.instance);
  }

  balancesWallets(): BalancesWallets {
    return balancesWallets(this.instance);
  }

  configurations(): Configuration {
    return configuration(this.instance);
  }

  configurationsNotifications(): ConfigurationNotification {
    return configurationNotification(this.instance);
  }

  dashboard(): Dashboard {
    return dashboard(this.instance);
  }

  foods(): Foods {
    return foods(this.instance);
  }

  horses(): Horses {
    return horses(this.instance);
  }

  horseExams(): HorseExams {
    return horseExams(this.instance);
  }

  horseExpenses(): HorseExpenses {
    return horseExpenses(this.instance);
  }

  horseFeeds(): HorseFeeds {
    return horseFeeds(this.instance);
  }

  horseFoods(): HorseFoods {
    return horseFoods(this.instance);
  }

  horseHorseshoes(): HorseHorseshoes {
    return horseHorseshoes(this.instance);
  }

  horseMedicines(): HorseMedicines {
    return horseMedicines(this.instance);
  }

  horseOdontology(): HorseOdontology {
    return horseOdontology(this.instance);
  }

  horseOwners(): HorseOwners {
    return horseOwners(this.instance);
  }

  horseVaccines(): HorseVaccines {
    return horseVaccines(this.instance);
  }

  horsesFeedsFoodsForecast(): HorsesFeedsFoodForecast {
    return horsesFeedsFoodForecast(this.instance);
  }

  invoices(): Invoices {
    return invoices(this.instance);
  }

  myTeam(): MyTeam {
    return myTeam(this.instance);
  }

  owners(): Owners {
    return owners(this.instance);
  }

  profiles(): Profile {
    return profile(this.instance);
  }

  profilesAbout(): ProfileAbout {
    return profileAbout(this.instance);
  }

  profilesCompanyData(): ProfileCompanyData {
    return profileCompanyData(this.instance);
  }

  riders(): Riders {
    return riders(this.instance);
  }

  riderAppointmentRules(): RiderAppointmentRules {
    return riderAppointmentRules(this.instance);
  }

  riderInvites(): RiderInvites {
    return riderInvites(this.instance);
  }

  riderLessonsPackage(): RiderLessonsPackage {
    return riderLessonsPackage(this.instance);
  }

  riderNotifications(): RidersNotifications {
    return ridersNotifications(this.instance);
  }

  riderPayments(): RiderPayments {
    return riderPayments(this.instance);
  }

  teachers(): Teachers {
    return teachers(this.instance);
  }
}

export const CompaniesFactoryAPI = (params: IClientConfigProps): CompaniesAPI => new CompaniesAPI(params);
