import { RidersHealthProvider } from '@modules/riders/contexts/Health';
import { RiderLessonPackageProvider } from '@modules/riders/contexts/LessonsPackage';
import { RidersNotificationsProvider } from '@modules/riders/contexts/Notifications';
import { RiderPaymentProvider } from '@modules/riders/contexts/Payments';
import { RidersProvider } from '@modules/riders/contexts/Riders';

export const AppRidersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <RidersProvider>
      <RidersHealthProvider>
        <RiderPaymentProvider>
          <RiderLessonPackageProvider>
            <RidersNotificationsProvider>{children}</RidersNotificationsProvider>
          </RiderLessonPackageProvider>
        </RiderPaymentProvider>
      </RidersHealthProvider>
    </RidersProvider>
  );
};
